<template>
  <div class="caseDetail">
    <PageTitle ttl-en="CASE" ttl-jp="_想定課題" />
    <div class="inner">
      <p class="caseDetail__mv js-scroll">
        <img src="/genbashiko/img/case/case05/mv.jpg" alt="" />
      </p>
      <section class="caseDetail__outline">
        <h1 class="js-scroll">
          塗装前のボデーの不良が見えにくく
          <br />検査作業に多大な手間とコストが必要
        </h1>
        <p class="caseDetail__outline__txt js-scroll">
          塗装前のボデーの不良を目視で検査する場合、一般的なライン照明で光の筋を照射しても、光が拡散してぼやけるためにキズや歪みなどの判別ができず、肉眼で発見するのは非常に難しいのが現状です。自動車メーカー各社でも、ダブルチェックの繰り返しにより、非常に手間がかかる工程です。表面にオイルを塗布して検査を行い、検査後に拭き取るといった工夫をするケースもありますが、完全な解決にはつながっていませんでした。
        </p>
        <div class="caseDetail__outline__cate js-scroll">
          <p>担当領域:</p>
          <ul>
            <li>
              <router-link
                class="category"
                :to="{ name: 'Case', query: { category: '既存の検査場を改修したい' }}"
              >
                既存の検査場を改修したい
              </router-link>
            </li>
            <li>
              <router-link
                class="category"
                :to="{ name: 'Case', query: { category: '検査現場を省人化したい' }}"
              >
                検査現場を省人化したい
              </router-link>
            </li>
            <li>
              <router-link
                class="category"
                :to="{ name: 'Case', query: { category: 'ボデー・プレス工程' }}"
              >
                ボデー・プレス工程
              </router-link>
            </li>
            <li>
              <router-link class="category" to="/case?category=現場調査">
                現場調査
              </router-link>
            </li>
            <li>
              <router-link class="category" to="/case?category=施策策定">
                施策策定
              </router-link>
            </li>
            <li>
              <router-link
                class="category"
                to="/case?category=コンサルティング"
              >
                コンサルティング
              </router-link>
            </li>
            <li>
              <router-link class="category" to="/case?category=製品開発">
                製品開発
              </router-link>
            </li>
            <li>
              <router-link class="category" to="/case?category=納品後サポート">
                納品後サポート
              </router-link>
            </li>
          </ul>
        </div>
      </section>
      <section class="caseDetail__env">
        <h3 class="caseDetail__env__ttl js-scroll">
          想定される環境
        </h3>
        <ul class="js-scroll">
          <li>ボデーなどの塗装前検査ライン</li>
        </ul>
      </section>
      <section class="caseDetail__ex">
        <h3 class="caseDetail__ex__ttl js-scroll">
          想定課題と具体的な改善施策例
        </h3>
        <div class="caseDetail__ex__problem js-scroll">
          <div class="caseDetail__ex__problem__p">
            <p class="ttl">
              課題
            </p>
            <p class="txt">
              塗装前の不良が見えにくい
            </p>
          </div>
          <div class="caseDetail__ex__problem__a">
            <span class="ttl">施策</span>
            <p class="txt">
              ボデー専用の光源を開発
            </p>
          </div>
          <div class="caseDetail__ex__problem__img">
            <p>
              <img src="/genbashiko/img/case/case05/image01.jpg" alt="" />
            </p>
            <p>
              <img src="/genbashiko/img/case/case05/image02.jpg" alt="" />
            </p>
          </div>
          <p class="caseDetail__ex__problem__cap">
            新たに開発したボデー専用のライン照明によって、ボデーを照射することで、従来は見ることができなかったキズや表面の歪みを発見できるようにしました。既存の検査ラインに納入したところ、多くの人員を必要としていた工程で、大幅な省人化を実現することができました。
          </p>
        </div>
        <p class="caseDetail__ex__download js-scroll">
          <a href="/genbashiko/upload/download_pdf/PA-5-1.pdf" target="_blank"
            >課題に関する資料ダウンロード</a
          >
        </p>
      </section>
    </div>
  </div>
</template>
<script>
import PageTitle from "@/components/PageTitle.vue";

export default {
  components: {
    PageTitle,
  },
  head: {
  title: {
    inner: 'Case05'
  },
  meta: [
    { property: 'og:title', content: 'Case05｜GENBA SHIKO' },
    { name: 'description', content: '自動車の塗装前のボデーの不良が見えにくいなら、当社開発のボデー専用の照明器具を導入することで肉眼で確認できる不良が増え検査の質をあげます。' },
    { property: 'og:description', content: '自動車の塗装前のボデーの不良が見えにくいなら、当社開発のボデー専用の照明器具を導入することで肉眼で確認できる不良が増え検査の質をあげます。' },
    { property: 'og:url', content: 'https://luci.co.jp/genbashiko/case/case05' },
    { property: 'og:type', content: 'article' }
    ]
  }
};
</script>
